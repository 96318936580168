<template>
  <v-container>
    <!-- Topic One -->
    <div class="d-flex">
      <h2>Icon Library</h2>
      <div class="mx-5" style="max-width: 100px">
        <v-text-field
          outlined
          v-model="size"
          label="Size"
        ></v-text-field>
      </div>
      <div class="mx-5" style="max-width: 200px">
        <v-text-field
          outlined
          v-model="color"
          label="Color"
        ></v-text-field>
      </div>
    </div>
    
    <div class="d-flex flex-wrap">
      <div
        v-for="(icon, index) in icons"
        :key="index"
        class="mt-10 mx-5 d-flex flex-column"
      >
        <!-- default  -->
        <h4 class="mb-2">{{icon.name}}</h4>
        <v-icon :size="size" :color="color">{{icon.name}}</v-icon>
      </div>
    </div>
    

  </v-container>
</template>

<script>

export default {
  data() {
    return {
      size: 40,
      icons: [
        { name: 'mdi-account' },
        { name: 'mdi-account-multiple' },
        { name: 'mdi-airballoon' },
        { name: 'mdi-airplane-landing' },
        { name: 'mdi-alarm' },
        { name: 'mdi-alert' },
        { name: 'mdi-all-inclusive' },
        { name: 'mdi-arrow-up' },
        { name: 'mdi-arrow-down' },
        { name: 'mdi-arrow-left' },
        { name: 'mdi-arrow-right' },
        { name: 'mdi-arrow-u-left-top' },
        { name: 'mdi-bell' },
        { name: 'mdi-bike' },
        { name: 'mdi-billboard' },
        { name: 'mdi-book-variant' },
        { name: 'mdi-briefcase' },
        { name: 'mdi-bug' },
        { name: 'mdi-calendar' },
        { name: 'mdi-calendar-outline' },
        { name: 'mdi-calendar-today' },
        { name: 'mdi-camera' },
        { name: 'mdi-camera-account' },
        { name: 'mdi-car' },
        { name: 'mdi-chart-bar' },
        { name: 'mdi-chart-pie' },
        { name: 'mdi-chart-timeline-variant' },
        { name: 'mdi-check' },
        { name: 'mdi-chevron-up' },
        { name: 'mdi-chevron-down' },
        { name: 'mdi-chevron-left' },
        { name: 'mdi-chevron-right' },
        { name: 'mdi-clipboard' },
        { name: 'mdi-clipboard-plus' },
        { name: 'mdi-clipboard-text' },
        { name: 'mdi-clock-check' },
        { name: 'mdi-clock-edit' },
        { name: 'mdi-clock-end' },
        { name: 'mdi-clock-start' },
        { name: 'mdi-clock-outline' },
        { name: 'mdi-cloud' },
        { name: 'mdi-cloud-upload' },
        { name: 'mdi-close' },
        { name: 'mdi-close-circle' },
        { name: 'mdi-code-tags' },
        { name: 'mdi-content-save' },
        { name: 'mdi-cog' },
        { name: 'mdi-comment-text' },
        { name: 'mdi-crown' },
        { name: 'mdi-delete' },
        { name: 'mdi-delete-empty' },
        { name: 'mdi-download' },
        { name: 'mdi-download-circle' },
        { name: 'mdi-earth' },
        { name: 'mdi-email' },
        { name: 'mdi-email-outline' },
        { name: 'mdi-exclamation' },
        { name: 'mdi-eye' },
        { name: 'mdi-eye-off' },
        { name: 'mdi-facebook' },
        { name: 'mdi-file-chart' },
        { name: 'mdi-file-code' },
        { name: 'mdi-filter' },
        { name: 'mdi-fingerprint' },
        { name: 'mdi-flash' },
        { name: 'mdi-folder' },
        { name: 'mdi-format-quote-close' },
        { name: 'mdi-gavel' },
        { name: 'mdi-github' },
        { name: 'mdi-google-plus' },
        { name: 'mdi-heart' },
        { name: 'mdi-help-circle-outline' },
        { name: 'mdi-home' },
        { name: 'mdi-information' },
        { name: 'mdi-laptop' },
        { name: 'mdi-linkedin' },
        { name: 'mdi-lock' },
        { name: 'mdi-lock-outline' },
        { name: 'mdi-logout' },
        { name: 'mdi-magnify' },
        { name: 'mdi-magnify-plus-outline' },
        { name: 'mdi-magnify-minus-outline' },
        { name: 'mdi-mail' },
        { name: 'mdi-map-marker' },
        { name: 'mdi-menu' },
        { name: 'mdi-menu-down' },
        { name: 'mdi-menu-right' },
        { name: 'mdi-menu-left' },
        { name: 'mdi-menu-up' },
        { name: 'mdi-message' },
        { name: 'mdi-minus' },
        { name: 'mdi-monitor' },
        { name: 'mdi-monitor-screenshot' },
        { name: 'mdi-moon-waning-crescent' },
        { name: 'mdi-movie-cog' },
        { name: 'mdi-newspaper-plus' },
        { name: 'mdi-office-building' },
        { name: 'mdi-pencil' },
        { name: 'mdi-pencil-circle-outline' },
        { name: 'mdi-paperclip' },
        { name: 'mdi-pinterest' },
        { name: 'mdi-phone' },
        { name: 'mdi-plus' },
        { name: 'mdi-progress-download' },
        { name: 'mdi-poll' },
        { name: 'mdi-puzzle' },
        { name: 'mdi-qrcode' },
        { name: 'mdi-reddit' },
        { name: 'mdi-refresh' },
        { name: 'mdi-send' },
        { name: 'mdi-shield-alert-outline' },
        { name: 'mdi-sofa' },
        { name: 'mdi-star' },
        { name: 'mdi-television-stop' },
        { name: 'mdi-timer-sand' },
        { name: 'mdi-triangle-small-down' },
        { name: 'mdi-triangle-small-up' },
        { name: 'mdi-truck-delivery' },
        { name: 'mdi-twitter' },
        { name: 'mdi-upload' },
        { name: 'mdi-video' },
        { name: 'mdi-video-check' },
        { name: 'mdi-video-off' },
        { name: 'mdi-view-dashboard' },
        { name: 'mdi-view-grid-outline' },
        { name: 'mdi-view-list-outline' },
        { name: 'mdi-view-module' },
        { name: 'mdi-volume-high' },
        { name: 'mdi-volume-off' },
        { name: 'mdi-volume-plus' },
        { name: 'mdi-vuetify' },
        { name: 'mdi-walk' },
        { name: 'mdi-wrench' },
        { name: 'mdi-white-balance-sunny' },
        { name: 'mdi-xml' },
        { name: 'mdi-youtube' },
        { name: 'mdi-content-copy' },
        { name: 'mdi-content-paste' },
        { name: 'mdi-clipboard-tick-outline' },
      ]
    }
  },

  created () {
  },

  methods: {
    
  }
}
</script>

<style scoped>
  h2 {
    width: fit-content;
    padding: 10px;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    border-left: 1px solid #eee;
  }
</style>